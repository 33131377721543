import logo from "../logo.png";

const Header = () => {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h1>Welcome to Makebacito</h1>
            <p>Your trusted partner in business consultancy.</p>
        </header>
    );
};

export default Header;