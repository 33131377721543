import React from 'react';
import './App.css';
import placeholderImage from './placeholder.png';
import { FaTools, FaProjectDiagram, FaChartLine, FaBullseye } from 'react-icons/fa';
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import Header from "./components/Header";

const App = () => {
    return (
        <div className="App">
        <Header />
            <main className="container">
                <section className="row align-items-center my-5">
                    <div className="col-md-6">
                        <h2>About Us</h2>
                        <p>Welcome to Makebacito, your trusted partner in business consultancy for the construction industry. At Makebacito, we understand the unique challenges and opportunities within the construction sector. Our mission is to provide tailored consultancy services that help construction businesses thrive in a competitive market. With a deep understanding of industry dynamics and a commitment to excellence, we support our clients in navigating complex projects, optimizing operations, and achieving sustainable growth.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={placeholderImage} alt="About Us" className="img-fluid" />
                    </div>
                </section>
                <section className="row align-items-center my-5">
                    <div className="col-md-6 order-md-2">
                        <h2>Our Mission</h2>
                        <p>At Makebacito, our team of experienced consultants brings a wealth of knowledge and expertise to every engagement. We offer a range of non-specific consultancy services designed to address the diverse needs of construction businesses. Whether you're looking to improve project management, enhance operational efficiency, or develop strategic initiatives, we are here to help. Our holistic approach ensures that we deliver practical solutions that drive real results, empowering your business to build a solid foundation for future success.</p>
                    </div>
                    <div className="col-md-6 order-md-1">
                        <img src={placeholderImage} alt="Our Mission" className="img-fluid" />
                    </div>
                </section>
                <section>
                    <h2>Our Services</h2>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="service-item">
                                <FaProjectDiagram className="service-icon" />
                                <h3>Operational Efficiency</h3>
                                <p>Enhance operational processes to improve overall productivity.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="service-item">
                                <FaTools className="service-icon" />
                                <h3>Project Management</h3>
                                <p>Optimize project timelines and resources for efficient delivery.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="service-item">
                                <FaChartLine className="service-icon" />
                                <h3>Strategic Planning</h3>
                                <p>Develop strategic initiatives for sustainable business growth.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="service-item">
                                <FaBullseye className="service-icon" />
                                <h3>Market Analysis</h3>
                                <p>Conduct comprehensive market analysis to inform business decisions.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h2>Contact Us</h2>
                    <ContactForm />
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default App;