import emailjs from "emailjs-com";
const ContactForm = () => {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_300rmab', 'template_4o29ptl', e.target, 'NsjoCNJyUzAhRIplB')
            .then((result) => {
                alert('Message Sent Successfully');
            }, (error) => {
                alert('An error occurred, please try again');
            });

        e.target.reset();
    };

    return (
        <form onSubmit={sendEmail} className="container">
            <div className="mb-3">
                <label className="form-label">Name:</label>
                <input type="text" name="user_name" className="form-control" required />
            </div>
            <div className="mb-3">
                <label className="form-label">Email:</label>
                <input type="email" name="user_email" className="form-control" required />
            </div>
            <div className="mb-3">
                <label className="form-label">Message:</label>
                <textarea name="message" className="form-control" required />
            </div>
            <button type="submit" className="btn btn-primary">Send</button>
        </form>
    );
};
export default ContactForm;